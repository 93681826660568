<template>
  <div class="authentication">
    <div class="content">
      <div class="title" v-if="location !== '陕西省'">
        <h3>兰州市残疾人就业创业网络服务平台 | 信息认证</h3>
        <router-link to="/">返回首页</router-link>
        <h4 data-v-a441cf08="">我是企业<i data-v-a441cf08="" class="el-icon el-icon-right"></i></h4>
      </div>
      <div class="title" v-else>
        <h3>信息认证</h3>
        <router-link to="/">返回首页</router-link>
        <h4 @click="goRouter('/companyLogin/authentication')">我是企业<i class="el-icon el-icon-right"></i></h4>

      </div>
      <div class="install">
        <h1>残疾人信息认证</h1>
        <p>完成认证后，您可进行下一步操作</p>
        <el-form :model="form" :rules="error" label-width="100px" ref="form" class="form">
          <el-form-item label="姓名" prop="name">
            <el-input v-model="form.name" class="wrap_input" placeholder="请输入真实姓名"></el-input>
          </el-form-item>
          <el-form-item label="身份证号" prop="idCard">
            <el-input v-model="form.idCard" class="wrap_input" placeholder="请输入身份证号"></el-input>
          </el-form-item>
        </el-form>
        <div class="btn">
          <el-button type="primary" @click="submit">立即提交</el-button>
          <el-button type="text" class="no_err" @click="isHide = true">暂不认证</el-button>
        </div>
      </div>
    </div>
    <el-dialog
        :visible.sync="isShow"
        width="312px"
        :show-close="false"
        :before-close="handleClose">
      <div class="dialog_content" v-if="active == 0">
        <img src="~/static/login/success.png" alt="">
        <h3>认证成功</h3>
        <p>恭喜您通过认证，可以获取更多服务啦</p>
        <el-button type="primary">返回({{ time }}S)</el-button>
      </div>
      <div class="dialog_content" v-if="active == 1">
        <img src="~/static/login/error.png" alt="">
        <h3>认证失败</h3>
        <p>{{ message }}</p>
        <el-button type="primary">返回({{ time }}S)</el-button>
      </div>
    </el-dialog>
    <el-dialog
        :visible.sync="isHide"
        width="312px"
        :show-close="false"
        :before-close="handleClose">
      <div class="dialog_content">
        <img src="~/static/login/resume.png" alt="">
        <h3>未通过认证的用户 <br>
          无法享受残疾人专属服务哦</h3>
        <div class="btn flex">
          <el-button type="info" @click="goRouter('/')">残忍拒绝</el-button>
          <el-button type="primary" @click="isHide = false">去认证</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "authentication",
  data() {
    return {
      form: {
        name: '',
        idCard: '',
        memberId: '',
        registerWay: 6,
      },
      error: {
        name: [
          {required: true, message: "请输入真实姓名", trigger: "blur"},
        ],
        idCard: [
          {required: true, message: "请输入身份证号", trigger: "blur"},
        ],
      },
      isShow: false,
      isHide: false,
      active: 1,
      time: 3,
      message: '很遗憾未通过认证，请检查后重新提交',
      location: '',
      touristType:''
    }
  },
  methods: {
    async submit() {
      // 提交认证
      let idCard = this.$sm2Encrypt(this.form.idCard);
      let res = await this.$api.userAuthenticationApi({
        name:this.form.name,
        memberId:this.form.memberId,
        registerWay:this.form.name,
        idCard:idCard
      });
      if (res.data.success) {
        this.active = 0;
        this.isShow = true;
        this.info();
      } else {
        this.active = 1;
        this.isShow = true;
        this.message = res.data.msg || '很遗憾未通过认证，请检查后重新提交';
      }
      this.time = 3;
      this.times();
    },
    noAuthentication() {

    },
    handleClose() {
      this.isShow = false;
      this.isHide = false;
      if (this.active == 0) {
        this.info();
      }
    },
    times() {
      if (this.time > 0) {
        this.time--;
        setTimeout(this.times, 1000);
      } else {
        this.time = 0;
        this.handleClose();
      }
    },
    goRouter(url, id) {
      const {href} = this.$router.resolve({
        path: url,
        id: id,
      });
      window.location.href = href;
    },
    async info() {
      // 刷新个人信息
      let res = await this.$api.loginRefreshApi();
      if (res.data.success) {
        let user = res.data.data;
        let userInfo = user;
        this.$store.commit("SET_USER_INFO", userInfo);
        if (userInfo.userType === "2") {
          this.$router.push('/enterprise');
        } else {
          this.$router.push('/userLogin/userInfo');
        }
      } else {
        this.$message.error("登录失败");
      }
    },
  },
  mounted() {
    this.location =  localStorage.getItem('locationSite');
    this.form.memberId = JSON.parse(localStorage.getItem('userType')).id;
    this.touristType = JSON.parse(localStorage.getItem('userType')).touristType;
    if(this.touristType == '1'){
      this.$message.success('您的密码过于简单，为了您账号安全，建议认证后尽快修改密码！')
    }
  },
  created() {
  }
}
</script>

<style scoped lang="less">
.authentication {
  width: 100%;
  min-height: 100vh;
  background-color: white;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100% 366px;

  .content {
    width: 1200px;
    margin: 0 auto;

    .title {
      position: relative;
      display: flex;
      padding-top: 26px;

      h3 {
        flex: 1;
        font-size: 18px;
        color: #28A46D;
      }
      h4{
        position: absolute;
        top: 80px;
        right: 0;
        font-size: 28px;
        color: #28A46D;
        cursor: pointer;
      }
      a {
        font-size: 18px;
        color: #66716C;
      }

      a:hover {
        color: #28A46D;
      }
    }

    .install {
      width: 60%;
      margin: 84px auto 0 auto;
      text-align: center;

      h1 {
        font-size: 28px;
        color: #00120A;
        margin-bottom: 10px;
      }

      p {
        font-size: 16px;
        color: #66716C;
      }

      /deep/ .form {
        width: 450px;
        margin: 36px auto 0 80px;

        .el-form-item {
          .el-input__inner {
            width: 336px;
          }

          .el-input__inner::placeholder {
            font-size: 14px;
            color: #99A09D;
          }
        }
      }

      .btn {
        margin-top: 100px;

        .el-button {
          display: block;
          margin: 0 auto;
          width: 336px;
        }

        .no_err {
          color: #99A09D;
        }
      }
    }
  }

  .el-dialog {
    .dialog_content {
      text-align: center;

      h3 {
        font-size: 18px;
        margin-top: 24px;
      }

      p {
        color: #66716C;
        padding: 6px 0;
      }

      .btn {
        justify-content: center;
        margin-top: 10px;

        .el-button {
          width: 102px;
        }

        .el-button:nth-of-type(1) {
          margin-right: 20px;
        }
      }

      .el-button {
        margin-top: 16px;
        width: 132px;
      }

    }
  }
}
</style>
